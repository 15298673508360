import type { ReactNode, FC } from 'react';

interface SegmentedButtonsField {
    children: ReactNode;
    label: string;
    optionalLabel?: string;
}

const SegmentedButtonField: FC<SegmentedButtonsField>= props => {
    const { children, label, optionalLabel = '' } = props;

    return (
        <div className="segmented-buttons-field">
            <div className="segmented-buttons-field-label">
                <div className="main-label">{label}</div>
                {optionalLabel && (
                    <div className="optional-label">{optionalLabel}</div>
                )}
            </div>
            {children}
        </div>
    );
};

export default SegmentedButtonField;
