import Icon from '2-core-elements/Icon/Icon';

interface SmallMonthlyNudgeProps {
    label: string;
}

export default function SmallMonthlyNudge({ label }: SmallMonthlyNudgeProps): JSX.Element {
    return (
        <div className="monthly-nodge-wrapper">
            <div className="monthly-nodge-text">{label}</div>
            <div className="monthly-nodge-icon"><Icon icon="arrow-nodge" /></div>
        </div>
    );
}
