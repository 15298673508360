

interface LoadingIndicatorProps {
    className?: string;
    loadingText?: React.ReactNode;
}

const LoadingIndicator = (props: LoadingIndicatorProps): JSX.Element => {
    const { className = '', loadingText } = props;

    const classOutput = `loading-indicator ${className}`;

    return (
        <div className={classOutput}>
            <div className="loading-icon" />
            {loadingText && (
                <div className="loading-text">{loadingText}</div>
            )}
        </div>
    );
};

export default LoadingIndicator;
