import { type FC, type ReactNode } from 'react';
import Icon, { type IconString } from '2-core-elements/Icon/Icon';
import { validateUrl } from 'utils/validateUrl';
import type { ClickOrPressEvent } from 'types/mouseEvents';

export type ButtonTarget = '_blank' | '_self' | '_parent' | '_top';
export type ButtonSkin = '1' | '1-dark' | '2' | '2-dark' | '3' | null; // null for change skin responsively
export type ButtonSize = 'sm' | 'md' | 'lg' | null;


export interface BtnContentProps {
    prefixIcon?: IconString | null;
    suffixIcon?: IconString | null;
    children?: ReactNode;
    prefixClass?: string;
}


export interface ButtonProps extends BtnContentProps {
    skin?: ButtonSkin;
    href?: string | null;
    target?: ButtonTarget;
    className?: string;
    isExternal?: boolean;
    size?: ButtonSize;
    onClick?: (event: ClickOrPressEvent) => void;
    disabled?: boolean;
    fullWidth?: boolean;
    onlyIcon?: boolean;
    type?: 'button' | 'submit';
    asDiv?: boolean;
    ariaLabel?: string;
}


const BtnContent: FC<BtnContentProps> = ({ prefixIcon, children, suffixIcon, prefixClass }) => {
    return (
        <>
            {prefixIcon && <Icon icon={prefixIcon} />}
            {children && <span className={`${prefixClass}-text`}>{children}</span>}
            {suffixIcon && <Icon icon={suffixIcon} />}
        </>
    );
};

const Button: FC<ButtonProps> = ({
    href,
    skin = '1',
    target,
    children,
    className = '',
    isExternal = false,
    size = '',
    onClick,
    disabled = false,
    fullWidth = false,
    type = 'button',
    prefixIcon,
    suffixIcon,
    onlyIcon,
    prefixClass = 'btn',
    asDiv,
    ariaLabel,
}) => {
    const btnWidthClass = fullWidth ? `${prefixClass}-full-width` : '';
    const btnSkinClass = `${prefixClass}-skin-${skin}`;
    const btnSizeClass = size ? `${prefixClass}-size-${size}` : `${prefixClass}-size-md`;
    const btnDisabledClass = disabled ? `${prefixClass}-disabled` : '';
    const btnOnlyIconClass = onlyIcon ? `${prefixClass}-only-icon` : '';
    const btnHasIconsClass = prefixIcon || suffixIcon ? `${prefixClass}-has-icons` : '';

    // eslint-disable-next-line max-len
    const classOutput = `${btnOnlyIconClass} ${btnHasIconsClass} ${btnWidthClass} ${btnSkinClass} ${btnSizeClass} ${className} ${btnDisabledClass}`;

    if (asDiv) {
        return (
            <div className={classOutput} aria-label={ariaLabel ?? undefined}>
                <BtnContent prefixIcon={prefixIcon} suffixIcon={suffixIcon} prefixClass={prefixClass}>
                    {children}
                </BtnContent>
            </div>
        );
    }

    if (href) {
        const validHref = validateUrl(href);

        const targetValue = isExternal ? '_blank' : target;
        const relValue = isExternal || target === '_blank' ? 'noopener noreferrer' : '';

        return (
            <a
                href={validHref}
                target={targetValue}
                rel={relValue}
                className={classOutput}
                onClick={
                    disabled
                        ? (e): void => {
                            e.preventDefault();
                        }
                        : onClick
                }
                aria-label={ariaLabel ?? undefined}
            >
                <BtnContent prefixIcon={prefixIcon} suffixIcon={suffixIcon} prefixClass={prefixClass}>
                    {children}
                </BtnContent>
            </a>
        );
    }

    return (
        <button
            // eslint-disable-next-line react/button-has-type
            type={type}
            className={classOutput}
            onClick={
                disabled
                    ? (e): void => {
                        e.preventDefault();
                    }
                    : onClick
            }
            disabled={disabled}
            aria-label={ariaLabel ?? undefined}
        >
            <BtnContent prefixIcon={prefixIcon} suffixIcon={suffixIcon} prefixClass={prefixClass}>
                {children}
            </BtnContent>
        </button>
    );
};

export default Button;
