import { type FC } from 'react';


interface InlineMessageProps {
    status: 'info' | 'warning' | 'error' | 'success';
    title?: string;
    children: React.ReactNode;
}

const InlineMessage: FC<InlineMessageProps> = ({ children, status = 'error', title }) => {
    return (
        <div className={`inline-message status-${status}`}>
            <div className="inline-message-content-box">
                {title && (
                    <div className="inline-message-title">{title}</div>
                )}
                {children}
            </div>
        </div>
    );
};

export default InlineMessage;
