import Button from '2-core-elements/Buttons/Button';
import { ReactComponent as PayPalButton } from 'assets/images/paypal-button.svg?react';
import { PAYMENT_TYPE } from '3-components/Donations/libs/common';

export type PaymentOptionType = {
    type: string;
    enable: boolean;
    buttonLabel: string | null;
    provider?: string;
}

interface PaymentOptionsProps {
    options: PaymentOptionType[];
    onSelect?: (selectedOption: string) => void;
    sslMessage?: string;
}

const PaymentOptions = ({ options, sslMessage, onSelect }: PaymentOptionsProps): JSX.Element => {
    return (
        <div className="payment-buttons-wrapper">
            <div className="payment-option-buttons">
                {options.map(option => {
                    const { type, buttonLabel, enable, provider } = option;

                    if (!enable) {
                        return false;
                    }

                    return (
                        <div className="payment-option" key={`payment-${type}`}>
                            {type === PAYMENT_TYPE.CREDIT_CARD && typeof provider !== 'undefined' && (
                                <Button
                                    type="submit"
                                    onClick={(): void => {
                                        if (onSelect) {
                                            onSelect(provider);
                                        }
                                    }}
                                    fullWidth={true}
                                    suffixIcon={'arrow-right'}
                                    prefixIcon={'credit-card'}
                                >
                                    {buttonLabel}
                                </Button>
                            )}

                            {type === PAYMENT_TYPE.PAYPAL && (
                                <Button
                                    type="submit"
                                    size={'lg'}
                                    onClick={(): void => {
                                        if (onSelect) {
                                            onSelect(PAYMENT_TYPE.PAYPAL);
                                        }
                                    }}
                                    ariaLabel="PayPal payment button"
                                    fullWidth={true}
                                    className="paypal-button"
                                >
                                    <PayPalButton />
                                </Button>
                            )}

                            {type === PAYMENT_TYPE.DIRECT_DEBIT && (
                                <Button
                                    type="submit"
                                    onClick={(): void => {
                                        if (onSelect) {
                                            onSelect(PAYMENT_TYPE.DIRECT_DEBIT);
                                        }
                                    }}
                                    fullWidth={true}
                                    suffixIcon={'arrow-right'}
                                    prefixIcon={'bank'}
                                    className="btn-direct-debit"
                                >
                                    {buttonLabel}
                                </Button>
                            )}
                        </div>
                    );
                })}
            </div>
            {sslMessage && (
                <div className="payment-ssl-message">
                    {sslMessage}
                </div>
            )}
        </div>
    );
};

export default PaymentOptions;
