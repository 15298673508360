const Visa = (): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="26" viewBox="0 0 40 26" fill="none">
            <rect x="0.5" y="0.5" width="38.2537" height="25" rx="4.5" fill="#1434CB" />
            <rect x="0.5" y="0.5" width="38.2537" height="25" rx="4.5" stroke="#DDDDDD" />
            <path d="M19.3993 9.08805L17.6617 17.2107H15.5605L17.2984 9.08805H19.3993ZM28.2397 14.3329L29.3459 11.2825L29.9825 14.3329H28.2397ZM30.584 17.2107H32.5276L30.8316 9.08805H29.0377C28.6346 9.08805 28.2944 9.32251 28.143 9.68397L24.9906 17.2107H27.1972L27.6353 15.9977H30.3311L30.584 17.2107ZM25.1002 14.5586C25.1092 12.4147 22.1355 12.2967 22.156 11.3391C22.1622 11.0473 22.4401 10.7378 23.0471 10.6583C23.3486 10.619 24.1777 10.5889 25.1192 11.0221L25.4878 9.29963C24.9822 9.11607 24.3315 8.93945 23.5222 8.93945C21.4454 8.93945 19.9837 10.0436 19.9713 11.6247C19.9582 12.7939 21.0148 13.4464 21.811 13.8351C22.6296 14.2334 22.9047 14.4884 22.9011 14.8445C22.8957 15.3897 22.2481 15.6299 21.6439 15.6394C20.5873 15.6561 19.9741 15.3543 19.4854 15.1265L19.1047 16.9063C19.5955 17.1317 20.5017 17.3276 21.4418 17.3377C23.6489 17.3377 25.093 16.2471 25.1002 14.5586ZM16.3968 9.08805L12.9925 17.2107H10.771L9.09583 10.7283C8.99403 10.329 8.90559 10.183 8.59632 10.0146C8.09166 9.74079 7.25742 9.48345 6.52344 9.32405L6.57357 9.08805H10.1489C10.6044 9.08805 11.0145 9.39141 11.1178 9.91612L12.0027 14.6167L14.1892 9.08805H16.3968Z" fill="white" />
        </svg>
    );
};

export default Visa;
