const MasterCard = (): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="26" viewBox="0 0 40 26" fill="none">
            <rect x="1.24658" y="0.5" width="38.2537" height="25" rx="4.5" fill="white" />
            <rect x="1.24658" y="0.5" width="38.2537" height="25" rx="4.5" stroke="#DDDDDD" />
            <path d="M23.0496 8.07072H17.5645V17.9276H23.0496V8.07072Z" fill="#FF5F00" />
            <path d="M17.9128 12.9991C17.9119 12.0498 18.127 11.1128 18.5419 10.2589C18.9567 9.40509 19.5603 8.65679 20.3071 8.07071C19.3823 7.34383 18.2717 6.8918 17.1022 6.76627C15.9327 6.64075 14.7514 6.84679 13.6935 7.36087C12.6355 7.87494 11.7436 8.6763 11.1195 9.67335C10.4955 10.6704 10.1646 11.8229 10.1646 12.9991C10.1646 14.1754 10.4955 15.3279 11.1195 16.3249C11.7436 17.322 12.6355 18.1233 13.6935 18.6374C14.7514 19.1515 15.9327 19.3575 17.1022 19.232C18.2717 19.1065 19.3823 18.6544 20.3071 17.9275C19.5603 17.3415 18.9567 16.5932 18.5419 15.7393C18.1271 14.8854 17.9119 13.9484 17.9128 12.9991Z" fill="#EB001B" />
            <path d="M30.4492 12.9991C30.4493 14.1753 30.1184 15.3278 29.4944 16.3249C28.8704 17.3219 27.9784 18.1233 26.9205 18.6374C25.8626 19.1515 24.6814 19.3575 23.5119 19.232C22.3424 19.1065 21.2318 18.6544 20.3071 17.9275C21.0532 17.3409 21.6563 16.5925 22.0711 15.7387C22.4858 14.885 22.7013 13.9483 22.7013 12.9991C22.7013 12.05 22.4858 11.1132 22.0711 10.2595C21.6563 9.4058 21.0532 8.65739 20.3071 8.07071C21.2318 7.34383 22.3424 6.89179 23.5119 6.76627C24.6814 6.64075 25.8626 6.8468 26.9205 7.36088C27.9784 7.87496 28.8704 8.67632 29.4944 9.67337C30.1184 10.6704 30.4493 11.8229 30.4492 12.9991Z" fill="#F79E1B" />
            <path d="M29.8512 16.8836V16.6818H29.9326V16.6406H29.7253V16.6818H29.8067V16.8836H29.8512ZM30.2535 16.8836V16.6402H30.19L30.1169 16.8076L30.0438 16.6402H29.9803V16.8836H30.0251V16.7L30.0937 16.8583H30.1402L30.2087 16.6996V16.8836H30.2535Z" fill="#F79E1B" />
        </svg>
    );
};

export default MasterCard;
