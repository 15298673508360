const UnionPay = (): JSX.Element => {
    return (
        <svg
            width={40}
            height={26}
            viewBox="0 0 40 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="Badge">
                <rect
                    x="0.753723"
                    y="0.5"
                    width="38.2537"
                    height={25}
                    rx="4.5"
                    fill="white"
                />
                <rect
                    x="0.753723"
                    y="0.5"
                    width="38.2537"
                    height={25}
                    rx="4.5"
                    stroke="#DDDDDD"
                />
                <g id="Union Pay">
                    <path
                        d="M12.9713 5.89471H18.6411C19.4326 5.89471 19.9253 6.54002 19.7406 7.33471L17.1005 18.6677C16.914 19.46 16.122 20.1053 15.3302 20.1053H9.66014C8.86837 20.1053 8.37616 19.46 8.56084 18.6677L11.2023 7.33471C11.3864 6.54002 12.1779 5.89471 12.9713 5.89471Z"
                        fill="#ED171F"
                    />
                    <path
                        d="M18.1696 5.89471H24.6903C25.4815 5.89471 25.1246 6.54002 24.9386 7.33471L22.2988 18.6677C22.1144 19.46 22.1722 20.1053 21.3788 20.1053H14.8587C14.0661 20.1053 13.5752 19.46 13.7615 18.6677L16.4003 7.33471C16.5868 6.54002 17.3781 5.89471 18.1696 5.89471Z"
                        fill="#082F67"
                    />
                    <path
                        d="M24.4323 5.89471H30.1013C30.8933 5.89471 31.3855 6.54002 31.2 7.33471L28.5604 18.6677C28.375 19.46 27.5832 20.1053 26.7888 20.1053H21.1214C20.328 20.1053 19.8366 19.46 20.0226 18.6677L22.6614 7.33471C22.8472 6.54002 23.6387 5.89471 24.4323 5.89471Z"
                        fill="#006A65"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23.8396 9.51803H24.0768L24.1219 9.51902C24.5873 9.52909 24.9781 9.53754 25.1765 9.80914C25.252 9.91695 25.2843 10.0571 25.2843 10.1973C25.2843 10.3159 25.2628 10.4237 25.2412 10.5099C25.155 10.8765 24.9717 11.1892 24.6914 11.394C24.2817 11.6959 23.7534 11.6959 23.2898 11.6959H23.2466C23.2143 11.7175 23.1819 11.7606 23.1819 11.7606C23.1819 11.7822 23.0741 12.2242 23.0741 12.2242C23.0741 12.2242 22.9447 12.8388 22.9232 12.9358C22.8369 12.925 22.6105 12.925 22.0822 12.9358C22.1038 12.828 22.6321 10.6609 22.6321 10.6609L22.8477 9.63663L22.8801 9.50725C22.9771 9.51803 23.2251 9.52882 23.8396 9.51803ZM24.0013 11.0921C24.1954 11.0059 24.3356 10.8118 24.3895 10.5854C24.4434 10.3374 24.3679 10.1434 24.1846 10.0679C24.1738 10.0679 23.6887 10.0248 23.6887 10.0248L23.5916 10.014V10.0571L23.5809 10.111L23.3437 11.1245L23.3329 11.1784H23.4623C23.6563 11.1784 23.8504 11.1676 24.0013 11.0921Z"
                        fill="white"
                    />
                    <path
                        d="M13.6187 9.52876C13.5971 9.6258 13.209 11.4371 13.209 11.4371C13.1227 11.8037 13.058 12.0624 12.8532 12.2349C12.7346 12.332 12.5944 12.3751 12.4327 12.3751C12.1739 12.3751 12.023 12.2457 12.0014 12.0085L11.9907 11.9223L12.0661 11.4263C12.0661 11.4263 12.4758 9.77674 12.5513 9.56111C12.5513 9.55033 12.5621 9.53955 12.5621 9.53955C11.7642 9.55033 11.6133 9.53955 11.6133 9.52876C11.6133 9.55033 11.5917 9.64736 11.5917 9.64736L11.1713 11.5018L11.1389 11.6635L11.0742 12.181C11.0742 12.332 11.1066 12.4613 11.1605 12.5692C11.3545 12.9034 11.8936 12.9573 12.2063 12.9573C12.6052 12.9573 12.9718 12.871 13.2305 12.7201C13.6545 12.472 13.771 12.085 13.8749 11.7399L13.8882 11.6959L13.9313 11.5126C13.9313 11.5126 14.3518 9.7983 14.4273 9.57189V9.55033C14.2937 9.54785 14.1817 9.54595 14.0877 9.54435C13.7722 9.53898 13.6602 9.53707 13.6187 9.52876Z"
                        fill="white"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M25.3059 14.2619L24.6159 16.5368H24.7992L24.7309 16.7681L24.7453 16.7201H24.9393C24.9932 16.7201 25.0364 16.6985 25.0795 16.6769C25.1118 16.6662 25.1442 16.6446 25.1873 16.5907L25.694 15.8791H25.0148L25.1334 15.491H25.9097L26.0175 15.1352H25.252L25.3814 14.6931H27.5161L27.3867 15.1352H26.6752L26.5566 15.491H27.2789L27.1603 15.8791H26.3841L26.2439 16.0408H26.5566L26.632 16.5368C26.6356 16.5548 26.638 16.5691 26.64 16.5811C26.644 16.6051 26.6464 16.6194 26.6536 16.6338C26.6644 16.6554 26.7614 16.6662 26.8153 16.6662H26.9124L26.7722 17.1406H26.3625C26.3136 17.1336 26.2783 17.113 26.2448 17.0935C26.2266 17.083 26.209 17.0727 26.19 17.0651C26.1469 17.0435 26.0822 16.9896 26.0606 16.8926L25.9851 16.3966L25.6293 16.8818C25.5215 17.0327 25.3706 17.1513 25.1118 17.1513H24.6159L24.6579 17.0112H24.4757L24.4326 17.1621H23.7749L23.8181 17.0112H22.4811L22.6213 16.5799H22.7507L23.5917 13.7983H24.2601L24.1846 14.0355C24.1846 14.0355 24.3679 13.9061 24.5296 13.863C24.6728 13.8272 25.3657 13.8062 25.5971 13.8002L25.597 13.7983H26.3086L26.3194 14.0678C26.3194 14.111 26.3517 14.1325 26.4379 14.1325H26.5889L26.4595 14.5746H26.0714C25.7371 14.5961 25.6078 14.456 25.6186 14.2942L25.6065 14.0166L25.5323 14.2619H25.3059ZM23.4299 16.5476H23.9582L24.1199 16.0409H23.5809L23.4299 16.5476ZM23.8612 15.1244L23.6779 15.7282C23.6779 15.7282 23.8828 15.6204 23.9906 15.588C24.0984 15.5665 24.2709 15.5449 24.2709 15.5449L24.4003 15.1244H23.8612ZM24.1199 14.2619L23.9474 14.8441C23.9474 14.8441 24.1415 14.7579 24.2493 14.7255C24.3571 14.6932 24.5189 14.6824 24.5189 14.6824L24.6482 14.2619H24.1199Z"
                        fill="white"
                    />
                    <path
                        d="M18.244 14.8118H20.0445L19.9151 15.243H19.1928L19.085 15.6096H19.8181L19.6887 16.0517H18.9556L18.7723 16.6446C18.7507 16.7093 18.9448 16.7201 19.0203 16.7201L19.3868 16.6662L19.2359 17.1622H18.4057C18.3545 17.1622 18.3101 17.1554 18.2564 17.1472C18.2422 17.1451 18.2274 17.1428 18.2116 17.1406C18.147 17.1298 18.1146 17.0867 18.0823 17.0436C18.0769 17.0346 18.0712 17.0261 18.0656 17.0179C18.0376 16.9767 18.0122 16.9393 18.0391 16.8495L18.2763 16.0624H17.8666L17.996 15.6096H18.4057L18.5135 15.243H18.1038L18.244 14.8118Z"
                        fill="white"
                    />
                    <path
                        d="M20.1847 14.0355H19.4515L19.5593 13.7983H18.8262L18.6429 14.1325C18.5998 14.208 18.5674 14.2511 18.5351 14.2727C18.5135 14.2835 18.4596 14.2942 18.3733 14.2942H18.2763L18.1469 14.7255H18.4704C18.6321 14.7255 18.7507 14.6716 18.8046 14.6393C18.8251 14.6324 18.8413 14.6288 18.8552 14.6258C18.8851 14.6191 18.9045 14.6148 18.934 14.5853L19.0418 14.4883H20.0553L20.1847 14.0355Z"
                        fill="white"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20.5081 13.7983H21.1335L21.0472 14.111C21.0472 14.111 21.2413 13.9492 21.3814 13.8953C21.5216 13.8414 21.8343 13.7983 21.8343 13.7983H22.8477L22.5027 14.9519C22.438 15.146 22.3733 15.2754 22.3302 15.3401C22.2871 15.4047 22.244 15.4479 22.1577 15.5018C22.0715 15.5449 21.996 15.5773 21.9205 15.5773C21.8558 15.588 21.7481 15.588 21.6079 15.588H20.6267L20.3464 16.5045C20.3414 16.5246 20.3363 16.5424 20.3319 16.5582C20.3171 16.6102 20.3083 16.6414 20.3248 16.6662C20.3356 16.6878 20.368 16.7093 20.4219 16.7093L20.8531 16.6662L20.7022 17.1729H20.217C20.0661 17.1729 19.9475 17.1729 19.872 17.1621C19.8508 17.1591 19.8296 17.1578 19.8089 17.1565C19.7559 17.1532 19.7059 17.15 19.6672 17.119C19.6612 17.113 19.6548 17.1069 19.6483 17.1005C19.6074 17.061 19.5593 17.0145 19.5593 16.9681C19.5593 16.9174 19.5784 16.8477 19.6075 16.741L19.6133 16.7201L20.5081 13.7983ZM20.8747 14.7794C20.8747 14.7794 21.0795 14.5961 21.4246 14.5314C21.5 14.5207 21.996 14.5207 21.996 14.5207L22.0715 14.2727H21.0256L20.8747 14.7794ZM21.845 14.9627H20.81L20.7453 15.1676H21.6402C21.7264 15.1589 21.7575 15.1641 21.772 15.1665C21.7756 15.1671 21.7782 15.1676 21.7803 15.1676L21.845 14.9627Z"
                        fill="white"
                    />
                    <path
                        d="M22.287 15.9438L22.3409 15.6635H21.8665L21.8018 15.8575C21.7911 15.8899 21.7803 15.9114 21.7587 15.9222C21.7372 15.933 21.694 15.9438 21.6509 15.9438H21.5L21.5107 15.6958H20.8962L20.8746 16.9141C20.8746 17.0004 20.8854 17.0543 20.9501 17.0974C21.0148 17.1513 21.2089 17.1621 21.4784 17.1621H21.8665L22.0067 16.6985L21.6725 16.72L21.5647 16.7308C21.5602 16.7264 21.5557 16.7237 21.5505 16.7207C21.5431 16.7163 21.5342 16.7111 21.5215 16.6985C21.5187 16.6927 21.515 16.6893 21.5112 16.6857C21.5008 16.6758 21.4892 16.6648 21.4892 16.6014V16.2888L21.8342 16.2672C22.0175 16.2672 22.1037 16.2025 22.1684 16.1486C22.2439 16.0947 22.2655 16.03 22.287 15.9438Z"
                        fill="white"
                    />
                    <path
                        d="M15.2467 12.9358C15.5809 12.925 15.6887 12.925 15.9691 12.925L16.0014 12.8927C16.0357 12.6615 16.0563 12.5595 16.1065 12.3114C16.1196 12.247 16.1346 12.1728 16.1524 12.084L16.1955 11.89L16.1984 11.8763C16.2827 11.4866 16.3241 11.2951 16.2602 11.1353C16.1847 10.952 16.0122 10.9088 15.8613 10.9088C15.7534 10.9088 15.5702 10.9304 15.3976 11.0167C15.3707 11.0328 15.341 11.049 15.3114 11.0652C15.2817 11.0814 15.2521 11.0975 15.2251 11.1137L15.279 10.9196L15.2467 10.8873C14.8998 10.9609 14.8091 10.973 14.5049 11.0135L14.4812 11.0167L14.4596 11.0382L14.4588 11.0446C14.4161 11.3541 14.3832 11.5923 14.244 12.2134C14.2171 12.3266 14.1901 12.4425 14.1632 12.5584C14.1362 12.6743 14.1092 12.7903 14.0823 12.9034L14.0931 12.9358C14.4273 12.925 14.5351 12.925 14.8262 12.925L14.8478 12.9034C14.8656 12.8231 14.8761 12.7686 14.8884 12.7047C14.9057 12.6145 14.9266 12.5055 14.9772 12.2781C14.991 12.216 15.0081 12.133 15.0263 12.0442C15.0653 11.8554 15.1096 11.6403 15.1389 11.545C15.2144 11.5126 15.2898 11.4803 15.3545 11.4803C15.5162 11.4803 15.5055 11.6312 15.4947 11.6851C15.4839 11.7822 15.43 12.0948 15.3653 12.3644L15.3222 12.5369C15.3042 12.6445 15.2788 12.7446 15.2522 12.8497C15.2468 12.871 15.2414 12.8925 15.2359 12.9142L15.2467 12.9358Z"
                        fill="white"
                    />
                    <path
                        d="M17.1227 12.925H16.7345C16.6851 12.925 16.6358 12.9272 16.5822 12.9297C16.5189 12.9326 16.4498 12.9358 16.3679 12.9358L16.3464 12.9142C16.3895 12.7525 16.4111 12.6878 16.4326 12.6339C16.4542 12.58 16.4758 12.5153 16.5081 12.3536C16.562 12.1379 16.5944 11.987 16.6159 11.8468C16.6267 11.7821 16.6348 11.7228 16.6429 11.6635C16.651 11.6042 16.659 11.5449 16.6698 11.4803L16.6914 11.4587C16.7738 11.4484 16.8414 11.4381 16.9013 11.429C16.9668 11.419 17.0232 11.4104 17.0795 11.4048C17.1873 11.394 17.3059 11.3724 17.4784 11.3293L17.4892 11.3401V11.3509C17.4731 11.421 17.4569 11.4884 17.4407 11.5557C17.4245 11.6231 17.4084 11.6905 17.3922 11.7606C17.376 11.8307 17.3598 11.8981 17.3437 11.9654C17.3275 12.0328 17.3113 12.1002 17.2952 12.1703C17.2305 12.4614 17.2089 12.5584 17.1873 12.6447C17.1823 12.68 17.1772 12.7083 17.1711 12.7427C17.1641 12.7818 17.1557 12.8289 17.1442 12.9034L17.1227 12.925Z"
                        fill="white"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.6025 11.1784C19.6995 11.3077 19.775 11.5126 19.6779 11.9438C19.6456 12.0732 19.5593 12.3967 19.3761 12.6015C19.1281 12.8711 18.7939 12.9573 18.4704 12.9573C18.341 12.9573 18.147 12.9358 18.0068 12.8818C17.5648 12.6986 17.6294 12.2242 17.6941 11.9546C17.748 11.6959 17.8559 11.4263 18.0715 11.2215C18.3734 10.9412 18.7615 10.9304 18.9232 10.9304C19.1928 10.9304 19.4515 10.9843 19.6025 11.1784ZM18.9205 11.9746C18.9259 11.9528 18.9304 11.9349 18.934 11.9223C19.0095 11.5881 18.9987 11.3724 18.8154 11.394C18.589 11.394 18.5028 11.6635 18.4273 11.9978C18.4241 12.015 18.421 12.0319 18.4179 12.0483C18.3999 12.1448 18.3842 12.2291 18.3842 12.3212C18.3842 12.5261 18.5243 12.5261 18.5459 12.5261C18.6321 12.5261 18.6968 12.4721 18.7615 12.3859C18.8424 12.2871 18.8933 12.0834 18.9205 11.9746Z"
                        fill="white"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M26.6212 12.9573C26.2762 12.9573 26.1792 12.9573 25.8558 12.9681L25.8342 12.9466L25.8686 12.8174C25.7513 12.9156 25.6248 12.9831 25.4892 12.9897C25.3706 12.9897 25.2412 12.9681 25.1011 12.9142C24.659 12.7417 24.7237 12.2565 24.7884 11.9978C24.8423 11.739 24.9501 11.4695 25.1658 11.2646C25.4784 10.9951 25.8342 10.9196 25.9852 10.9735C26.097 11.017 26.1722 11.0856 26.2248 11.1666L26.2331 11.1029L26.2547 11.0813C26.3256 11.0703 26.3856 11.061 26.4392 11.0527C26.6465 11.0207 26.7591 11.0033 27.0417 10.952L27.0525 10.9843L26.8584 11.7929C26.7574 12.231 26.7089 12.5044 26.6669 12.742L26.6667 12.7427C26.655 12.8089 26.6438 12.8723 26.632 12.9358L26.6212 12.9573ZM26.1373 11.7077C26.1346 11.5224 26.0529 11.4156 25.9205 11.4156C25.6941 11.4156 25.6078 11.6959 25.5323 12.0193L25.5264 12.0517C25.507 12.1576 25.4892 12.2546 25.4892 12.3428C25.4892 12.5476 25.6294 12.5476 25.6509 12.5476C25.7372 12.5476 25.8774 12.5045 25.9313 12.4183C25.9639 12.3734 25.9934 12.3054 26.0187 12.2347C26.0405 12.1456 26.0617 12.0559 26.0822 11.9654C26.1041 11.8681 26.1221 11.7834 26.1373 11.7077Z"
                        fill="white"
                    />
                    <path
                        d="M21.6186 12.925C21.3275 12.925 21.2305 12.925 20.907 12.9358L20.8962 12.9142C20.9017 12.8925 20.9071 12.871 20.9125 12.8497C20.9391 12.7446 20.9645 12.6445 20.9825 12.5369L21.0256 12.3644C21.0903 12.0948 21.1442 11.7822 21.155 11.6851C21.1658 11.6312 21.1765 11.4803 21.0148 11.4803C20.9501 11.4803 20.8747 11.5126 20.7992 11.545C20.7699 11.6403 20.7256 11.8553 20.6867 12.0442C20.6684 12.133 20.6513 12.216 20.6375 12.2781C20.5695 12.584 20.5551 12.6756 20.5362 12.7956C20.5311 12.8279 20.5257 12.8623 20.5189 12.9034L20.4973 12.925C20.2062 12.925 20.0984 12.925 19.7642 12.9358L19.7534 12.9034C19.7803 12.7902 19.8073 12.6743 19.8342 12.5584C19.8612 12.4425 19.8881 12.3266 19.9151 12.2134C20.0543 11.5924 20.0872 11.3541 20.1299 11.0446L20.1307 11.0382L20.1523 11.0167L20.176 11.0135C20.4802 10.973 20.5709 10.9609 20.9178 10.8873L20.9501 10.9196L20.8962 11.1137C20.9232 11.0975 20.9528 11.0814 20.9825 11.0652C21.0121 11.049 21.0418 11.0328 21.0687 11.0167C21.2412 10.9304 21.4245 10.9088 21.5216 10.9088C21.6725 10.9088 21.845 10.952 21.9205 11.1353C21.9844 11.2951 21.943 11.4866 21.8587 11.8763L21.8558 11.89L21.8127 12.084C21.7353 12.4279 21.7128 12.5524 21.6795 12.7363C21.671 12.7831 21.6619 12.8337 21.6509 12.8927L21.6186 12.925Z"
                        fill="white"
                    />
                    <path
                        d="M29.4352 10.9519L29.4029 10.9196C29.0471 10.9951 28.9932 11.0058 28.6697 11.049L28.6482 11.0705V11.0813C28.5215 11.375 28.4624 11.478 28.4054 11.5776C28.3556 11.6644 28.3073 11.7487 28.2169 11.9546V11.9007L28.1738 10.9519L28.1414 10.9196C28.0562 10.9371 27.9903 10.9512 27.935 10.9629C27.7524 11.0018 27.6864 11.0159 27.4298 11.049L27.4083 11.0705C27.4083 11.0813 27.4083 11.0921 27.3975 11.1029C27.4299 11.2649 27.4319 11.2808 27.4491 11.4205C27.4549 11.4667 27.4623 11.5265 27.473 11.6096C27.4838 11.6959 27.4945 11.7794 27.5053 11.863C27.5161 11.9465 27.5269 12.0301 27.5377 12.1163C27.5491 12.2152 27.5591 12.2954 27.5693 12.3761C27.5879 12.5242 27.6068 12.6744 27.6347 12.9465C27.5485 13.0902 27.4873 13.2011 27.4321 13.3011C27.3422 13.4638 27.2682 13.598 27.128 13.7983L27.1387 13.8306C27.473 13.8198 27.5484 13.8198 27.7964 13.8198L27.8503 13.7551C28.0228 13.3778 29.4352 10.9519 29.4352 10.9519Z"
                        fill="white"
                    />
                    <path
                        d="M17.4893 10.7794C17.651 10.8657 17.6294 11.049 17.4354 11.1783C17.2413 11.3077 16.961 11.3401 16.7993 11.2538C16.6375 11.1568 16.6591 10.9735 16.8532 10.8441C17.0472 10.7147 17.3276 10.6824 17.4893 10.7794Z"
                        fill="white"
                    />
                </g>
            </g>
        </svg>

    );
};

export default UnionPay;
