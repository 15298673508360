
import kebabCase from 'lodash.kebabcase';
import type { ClickOrPressEvent } from 'types/mouseEvents';

// *****To update this file see `tools/generate-icon-imports.mjs`*****

import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg?react';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg?react';
import { ReactComponent as ArrowNodge } from 'assets/icons/arrow-nodge.svg?react';
import { ReactComponent as ArrowRightSlim } from 'assets/icons/arrow-right-slim.svg?react';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg?react';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg?react';
import { ReactComponent as Bank } from 'assets/icons/bank.svg?react';
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg?react';
import { ReactComponent as CheckCircle } from 'assets/icons/check-circle.svg?react';
import { ReactComponent as Check } from 'assets/icons/check.svg?react';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg?react';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg?react';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg?react';
import { ReactComponent as ChevronUp } from 'assets/icons/chevron-up.svg?react';
import { ReactComponent as CreditCard } from 'assets/icons/credit-card.svg?react';
import { ReactComponent as Error } from 'assets/icons/error.svg?react';
import { ReactComponent as EyeOpen } from 'assets/icons/eye-open.svg?react';
import { ReactComponent as LockFilled } from 'assets/icons/lock-filled.svg?react';
import { ReactComponent as Menu } from 'assets/icons/menu.svg?react';
import { ReactComponent as Minus } from 'assets/icons/minus.svg?react';
import { ReactComponent as MoreHorizontal } from 'assets/icons/more-horizontal.svg?react';
import { ReactComponent as Plus } from 'assets/icons/plus.svg?react';
import { ReactComponent as Protection } from 'assets/icons/protection.svg?react';
import { ReactComponent as Publications } from 'assets/icons/publications.svg?react';
import { ReactComponent as Restart } from 'assets/icons/restart.svg?react';
import { ReactComponent as Search } from 'assets/icons/search.svg?react';
import { ReactComponent as Star } from 'assets/icons/star.svg?react';
import { ReactComponent as Time } from 'assets/icons/time.svg?react';
import { ReactComponent as UserSolid } from 'assets/icons/user-solid.svg?react';
import { ReactComponent as Volunteer } from 'assets/icons/volunteer.svg?react';
import { ReactComponent as XCircle } from 'assets/icons/x-circle.svg?react';
import { ReactComponent as X } from 'assets/icons/x.svg?react';

export type IconString =

| 'arrow-down'
| 'arrow-left'
| 'arrow-nodge'
| 'arrow-right-slim'
| 'arrow-right'
| 'arrow-up'
| 'bank'
| 'calendar'
| 'check-circle'
| 'check'
| 'chevron-down'
| 'chevron-left'
| 'chevron-right'
| 'chevron-up'
| 'credit-card'
| 'error'
| 'eye-open'
| 'lock-filled'
| 'menu'
| 'minus'
| 'more-horizontal'
| 'plus'
| 'protection'
| 'publications'
| 'restart'
| 'search'
| 'star'
| 'time'
| 'user-solid'
| 'volunteer'
| 'x-circle'
| 'x';

export type IconSVG = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
export const allIconComponents: Record<IconString, IconSVG> = {
    'arrow-down': ArrowDown,
    'arrow-left': ArrowLeft,
    'arrow-nodge': ArrowNodge,
    'arrow-right-slim': ArrowRightSlim,
    'arrow-right': ArrowRight,
    'arrow-up': ArrowUp,
    bank: Bank,
    calendar: Calendar,
    'check-circle': CheckCircle,
    check: Check,
    'chevron-down': ChevronDown,
    'chevron-left': ChevronLeft,
    'chevron-right': ChevronRight,
    'chevron-up': ChevronUp,
    'credit-card': CreditCard,
    error: Error,
    'eye-open': EyeOpen,
    'lock-filled': LockFilled,
    menu: Menu,
    minus: Minus,
    'more-horizontal': MoreHorizontal,
    plus: Plus,
    protection: Protection,
    publications: Publications,
    restart: Restart,
    search: Search,
    star: Star,
    time: Time,
    'user-solid': UserSolid,
    volunteer: Volunteer,
    'x-circle': XCircle,
    x: X,
};

interface IconProps {
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    icon: IconString | string;
    className?: string;
    // eslint-disable-next-line no-unused-vars
    onClick?: (event: ClickOrPressEvent) => void;
    ref?: React.LegacyRef<SVGSVGElement>;
}

const Icon: React.FC<IconProps> = props => {
    const { icon, className, onClick, ref } = props;
    const iconName = kebabCase(icon) as IconString;
    const SpecificIcon = allIconComponents[iconName];

    if (!SpecificIcon) {
        return null;
    }

    const isClickableClass = onClick ? 'svg-icon-clickable' : '';

    const iconClass = `svg-icon ${isClickableClass} ${className ? className : ''}`;

    return <SpecificIcon ref={ref} onClick={onClick} className={iconClass} />;
};

export default Icon;
