import Icon, { type IconString } from '2-core-elements/Icon/Icon';
import type { ChangeEvent, FC, FocusEvent, ReactNode } from 'react';
import { cn } from 'utils/className';

interface SegmentedButtonItemProps {
    className?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
    required?: boolean;
    disabled?: boolean;
    label: string;
    id: string;
    name?: string;
    value?: string | number;
    checked?: boolean;
    icon?: IconString;
    alwaysShowIcon?: boolean;
}

interface SegmentedButtonsProps {
    children: ReactNode;
    className?: string;
    size?: 'large' | 'small';
}

export const SegmentedButtonItem: FC<SegmentedButtonItemProps>= (props: SegmentedButtonItemProps) => {
    const { icon = 'check-circle', label = 'Option', name, value, id, className = '', onChange, required = false, disabled = false, checked, onBlur, alwaysShowIcon = false } = props;

    return (
        <div className={`segmented-button-item ${className}`}>
            <label htmlFor={id} className="segmented-button-el">
                <input type="radio" id={id} name={name} value={value} required={required} disabled={disabled} onChange={onChange} onBlur={onBlur} checked={checked} />
                <div className="input-label-wrapper">
                    <div className="input-label">
                        {icon && (
                            <div className={`input-icon ${alwaysShowIcon ? 'always-show' : ''}`}>
                                <Icon icon={icon} />
                            </div>
                        )}
                        <div className="input-text">{label}</div>
                    </div>
                </div>
            </label>
        </div>
    );
};

const SegmentedButtons: FC<SegmentedButtonsProps> = props => {
    const { children, className, size = 'large' } = props;

    return (
        <div className={cn('segmented-button-wrapper', className, `is-size-${size}`)}>
            {children}
        </div>
    );
};

export default SegmentedButtons;
