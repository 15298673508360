import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import type { Exact } from 'cms/cms-types';
import { CREDIT_CARD_PROVIDER, PAYMENT_TYPE } from '3-components/Donations/libs/common';

export type DonationFrequencyOption = 'All' | 'Once' | 'Monthly';
export type CreditCardOptions = 'AuthorizeNet' | 'IPG' | 'Bambora';

interface PaymentFieldOption {
    onceOption: {
        useCreditCard: boolean;
        creditCardLabel: string | null;
        usePayPal: boolean;
        payPalLabel: string;
        defaultAmount: number;
        amountOne: number;
        amountTwo: number;
        amountThree: number;
        minimumAmount: number;
    };
    monthlyOption: {
        useDirectDebit: boolean;
        directDebitLabel: string | null;
        useCreditCard: boolean;
        creditCardLabel: string | null;
        defaultAmount: number;
        amountOne: number;
        amountTwo: number;
        amountThree: number;
        minimumAmount: number;
    };
    donationFrequencyOption: DonationFrequencyOption;
    donationDefaultOption: null | 'Once' | 'Monthly';
    donationPaymentGateway: null | CreditCardOptions;
}

interface FrequencyFieldData {
    intentionLabel: string | null;
    onceLabel: string | null;
    monthlyLabel: string | null;
    isHideSmallNudge: boolean;
    smallNudgeLabel: string | null;
}

interface AmountFieldData {
    selectLabel: string | null;
    customAmountLabel: string | null;
    acceptPaymentLabel: string | null;
    currencyLabel: string | null;
    defaultAmountSelected: number;
}

interface DonationFeeData {
    isHideDonationFeeNudge: boolean;
    isDefaultDonationFeeChecked: boolean;
    feeAmount: number;
    feeLabel: string | null;
    feeMessage: string | null;
}

export interface WidgetCMSSettings {
    paymentOption: PaymentFieldOption;
    frequencyData: FrequencyFieldData;
    amountData: AmountFieldData;
    donationFeeData: DonationFeeData;
}

type AmountOption = {
    label: string;
    value: string;
    asDefault: boolean;
}

export type PaymentOption = {
    type: string;
    enable: boolean;
    buttonLabel: string | null;
    provider?: string;
}

interface WidgetCMSData {
    currency: string;
    currencySymbol: string;
    frequencyField: {
        label: string;
        options: {
            label: string;
            value: 'once' | 'monthly';
            asDefault: boolean;
            enable: boolean;
        }[];
        smallNudgeSettings: {
            enable: boolean;
            label: string;
        };
    };
    amountField: {
        label: string;
        customLabel: string;
        options: {
            once: AmountOption[];
            monthly: AmountOption[];
        };
        minimumAmount: {
            once: number;
            monthly: number;
        };
        paymentIconLabel: string;
    };
    additionalFeeSettings: {
        enable: boolean;
        defaultChecked: boolean;
        label: string;
        feeAmount: number;
        message: string;
    };
    paymentMethods: {
        type: 'once' | 'monthly';
        payments: PaymentOption[];
    }[];
}

function fetcher<TData, TVariables>(endpoint: string, variables?: TVariables) {
    return async(): Promise<TData> => {
        const params = variables ? new URLSearchParams(variables).toString() : '';

        const res = await fetch(`${endpoint}?${params}`, { method: 'GET' });

        const json = await res.json();

        if (json.errors) {
            const { message } = json.errors[0];

            throw new Error(message);
        }

        return json;
    };
}

export const resolveWidgetSettings = (data: WidgetCMSSettings): WidgetCMSData => {
    const currency = 'AUD';
    const currencySymbol = '$';

    const amounts = ['amountOne', 'amountTwo', 'amountThree'];

    const onceAmountOptions = amounts.map(opt => {
        return {
            label: `${currencySymbol}${data.paymentOption.onceOption[opt]}`,
            value: `${data.paymentOption.onceOption[opt]}`,
            asDefault: data.paymentOption.onceOption.defaultAmount === data.paymentOption.onceOption[opt],
        };
    });

    const creditCardProvider = data.paymentOption.donationPaymentGateway;

    const monthlyAmountOptions = amounts.map(opt => {
        return {
            label: `${currencySymbol}${data.paymentOption.monthlyOption[opt]}`,
            value: `${data.paymentOption.monthlyOption[opt]}`,
            asDefault: data.paymentOption.monthlyOption.defaultAmount === data.paymentOption.monthlyOption[opt],
        };
    });

    const minimumAmount = {
        once: data.paymentOption.onceOption.minimumAmount ?? 1,
        monthly: data.paymentOption.monthlyOption.minimumAmount ?? 1,
    };

    const isAllFrequencyEnabled = data.paymentOption.donationFrequencyOption === 'All';
    const isOnceOptionEnabled = isAllFrequencyEnabled || data.paymentOption.donationFrequencyOption === 'Once';
    const isMonthlyOptionEnabled = isAllFrequencyEnabled || data.paymentOption.donationFrequencyOption === 'Monthly';

    return {
        currency,
        currencySymbol,
        frequencyField: {
            label: data.frequencyData.intentionLabel ?? '',
            options: [
                {
                    label: data.frequencyData.onceLabel ?? '',
                    value: 'once',
                    asDefault: (isAllFrequencyEnabled && data.paymentOption.donationDefaultOption === 'Once') || (isOnceOptionEnabled && !isAllFrequencyEnabled),
                    enable: isOnceOptionEnabled,
                },
                {
                    label: data.frequencyData.monthlyLabel ?? '',
                    value: 'monthly',
                    asDefault: (isAllFrequencyEnabled && data.paymentOption.donationDefaultOption === 'Monthly') || (isMonthlyOptionEnabled && !isAllFrequencyEnabled),
                    enable: isMonthlyOptionEnabled,
                }
            ],
            smallNudgeSettings: {
                enable: !data.frequencyData.isHideSmallNudge,
                label: data.frequencyData.smallNudgeLabel ?? '',
            },
        },
        amountField: {
            label: data.amountData.selectLabel ?? '',
            customLabel: data.amountData.customAmountLabel ?? '',
            options: {
                once: onceAmountOptions,
                monthly: monthlyAmountOptions,
            },
            minimumAmount,
            paymentIconLabel: data.amountData.acceptPaymentLabel ?? '',
        },
        additionalFeeSettings: {
            enable: !data.donationFeeData.isHideDonationFeeNudge,
            defaultChecked: data.donationFeeData.isDefaultDonationFeeChecked,
            label: data.donationFeeData.feeLabel ?? '',
            feeAmount: data.donationFeeData.feeAmount,
            message: data.donationFeeData.feeMessage ?? '',
        },
        paymentMethods: [
            {
                type: 'once',
                payments: [
                    {
                        type: PAYMENT_TYPE.PAYPAL,
                        enable: data.paymentOption.onceOption.usePayPal,
                        buttonLabel: data.paymentOption.onceOption.payPalLabel,
                    },
                    {
                        type: PAYMENT_TYPE.CREDIT_CARD,
                        enable: data.paymentOption.onceOption.useCreditCard,
                        buttonLabel: data.paymentOption.onceOption.creditCardLabel,
                        provider: creditCardProvider ? CREDIT_CARD_PROVIDER[creditCardProvider] : '',
                    }
                ],
            },
            {
                type: 'monthly',
                payments: [
                    {
                        type: PAYMENT_TYPE.CREDIT_CARD,
                        enable: data.paymentOption.monthlyOption.useCreditCard,
                        buttonLabel: data.paymentOption.monthlyOption.creditCardLabel,
                        provider: creditCardProvider ? CREDIT_CARD_PROVIDER[creditCardProvider] : '',
                    },
                    {
                        type: PAYMENT_TYPE.DIRECT_DEBIT,
                        enable: data.paymentOption.monthlyOption.useDirectDebit,
                        buttonLabel: data.paymentOption.monthlyOption.directDebitLabel,
                    }
                ],
            }
        ],
    };
};

type WidgetCMSSettingsVariable = Exact<{
    lang?: string;
}>

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const useWidgetSettings = <
    TData = WidgetCMSSettings,
      TError = unknown
    >(
        dataSource: {endpoint: string},
        variables?: WidgetCMSSettingsVariable,
        options?: UseQueryOptions<WidgetCMSSettings, TError, TData>
    ) => {
    return useQuery<WidgetCMSSettings, TError, TData>(
        variables === undefined ? ['WidgetSettingGetter'] : ['WidgetSettingGetter', variables],
        fetcher<WidgetCMSSettings, WidgetCMSSettingsVariable>(dataSource.endpoint, variables),
        options);
};


