const Paypal = (): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="26" viewBox="0 0 40 26" fill="none">
            <rect x="0.992676" y="0.5" width="38.2537" height="25" rx="4.5" fill="white" />
            <rect x="0.992676" y="0.5" width="38.2537" height="25" rx="4.5" stroke="#DDDDDD" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.64228 9.50859H7.49446C7.34748 9.50859 7.22248 9.6154 7.19956 9.76043L6.33087 15.2674C6.3136 15.3761 6.39777 15.4741 6.508 15.4741H7.53339C7.68037 15.4741 7.80537 15.3673 7.82829 15.222L8.06259 13.7367C8.08519 13.5912 8.2105 13.4845 8.35717 13.4845H9.0371C10.4519 13.4845 11.2684 12.7999 11.4818 11.4434C11.5779 10.8499 11.4859 10.3836 11.2078 10.0569C10.9026 9.69838 10.3611 9.50859 9.64228 9.50859ZM9.89008 11.52C9.77264 12.2906 9.18377 12.2906 8.61439 12.2906H8.29027L8.51765 10.8513C8.53115 10.7644 8.60652 10.7004 8.69447 10.7004H8.84302C9.23087 10.7004 9.59679 10.7004 9.78587 10.9214C9.89851 11.0533 9.93309 11.2492 9.89008 11.52ZM16.0626 11.4953H15.0341C14.9465 11.4953 14.8707 11.5593 14.8572 11.6464L14.8117 11.934L14.7398 11.8298C14.5171 11.5066 14.0207 11.3986 13.5251 11.3986C12.3884 11.3986 11.4177 12.2593 11.2286 13.4668C11.1304 14.069 11.27 14.6449 11.6117 15.0466C11.9252 15.4158 12.3737 15.5697 12.9073 15.5697C13.823 15.5697 14.331 14.9809 14.331 14.9809L14.285 15.2667C14.2677 15.376 14.3519 15.4739 14.4615 15.4739H15.388C15.5354 15.4739 15.6596 15.3671 15.6829 15.2218L16.2388 11.7019C16.2564 11.5936 16.1725 11.4953 16.0626 11.4953ZM14.6289 13.4969C14.5296 14.0844 14.0633 14.4788 13.4685 14.4788C13.1699 14.4788 12.9311 14.383 12.7779 14.2015C12.6259 14.0213 12.568 13.7647 12.6164 13.479C12.7092 12.8964 13.1834 12.4892 13.7691 12.4892C14.0611 12.4892 14.2986 12.5862 14.4549 12.7693C14.6116 12.9543 14.6738 13.2123 14.6289 13.4969ZM20.5069 11.4951H21.5405C21.6853 11.4951 21.7697 11.6574 21.6874 11.7762L18.2498 16.7376C18.1941 16.818 18.1024 16.8658 18.0045 16.8658H16.9722C16.8268 16.8658 16.7419 16.7022 16.8261 16.5832L17.8965 15.0724L16.7581 11.7318C16.7187 11.6157 16.8044 11.4951 16.928 11.4951H17.9436C18.0756 11.4951 18.192 11.5817 18.23 11.708L18.8342 13.7256L20.2598 11.626C20.3156 11.5441 20.4083 11.4951 20.5069 11.4951Z" fill="#253B80" />
            <path fillRule="evenodd" clipRule="evenodd" d="M31.7136 15.267L32.5951 9.65923C32.6086 9.57219 32.684 9.5081 32.7716 9.50781H33.764C33.8736 9.50781 33.9577 9.60605 33.9404 9.71475L33.0712 15.2215C33.0485 15.3668 32.9235 15.4736 32.7763 15.4736H31.89C31.7804 15.4736 31.6963 15.3757 31.7136 15.267ZM24.9629 9.5081H22.8147C22.668 9.5081 22.543 9.61491 22.5201 9.75994L21.6514 15.267C21.6341 15.3757 21.7182 15.4736 21.828 15.4736H22.9302C23.0327 15.4736 23.1203 15.3989 23.1363 15.2972L23.3828 13.7362C23.4054 13.5907 23.5307 13.484 23.6774 13.484H24.357C25.7721 13.484 26.5885 12.7994 26.802 11.4429C26.8983 10.8494 26.8057 10.3831 26.5278 10.0564C26.2228 9.69789 25.6817 9.5081 24.9629 9.5081ZM25.2107 11.5195C25.0935 12.2901 24.5046 12.2901 23.9349 12.2901H23.6111L23.8388 10.8509C23.8524 10.764 23.9271 10.6999 24.0153 10.6999H24.1638C24.5514 10.6999 24.9177 10.6999 25.1066 10.9209C25.2194 11.0528 25.2537 11.2487 25.2107 11.5195ZM31.3827 11.4948H30.3548C30.2665 11.4948 30.1915 11.5588 30.1783 11.6459L30.1328 11.9335L30.0605 11.8293C29.8379 11.5061 29.3417 11.3981 28.8461 11.3981C27.7094 11.3981 26.739 12.2589 26.5499 13.4663C26.452 14.0685 26.5911 14.6444 26.9328 15.0461C27.2468 15.4153 27.6948 15.5692 28.2283 15.5692C29.144 15.5692 29.6518 14.9804 29.6518 14.9804L29.6061 15.2662C29.5888 15.3755 29.6729 15.4735 29.7832 15.4735H30.7093C30.856 15.4735 30.9809 15.3666 31.0039 15.2213L31.5601 11.7014C31.5771 11.5931 31.493 11.4948 31.3827 11.4948ZM29.9491 13.4964C29.8504 14.0839 29.3834 14.4783 28.7886 14.4783C28.4905 14.4783 28.2512 14.3825 28.0979 14.201C27.9459 14.0208 27.8888 13.7642 27.9366 13.4785C28.0297 12.8959 28.5034 12.4887 29.0891 12.4887C29.3812 12.4887 29.6186 12.5857 29.7751 12.7688C29.9323 12.9538 29.9946 13.2119 29.9491 13.4964Z" fill="#179BD7" />
        </svg>
    );
};

export default Paypal;
