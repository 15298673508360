import SegmentedButtons, { SegmentedButtonItem } from '3-components/SegmentedButtons/SegmentedButtons';
import SegmentedButtonField from '3-components/SegmentedButtons/SegmentedButtonsField/SegmentedButtonField';
import { useEffect, useState } from 'react';
import SmallMonthlyNudge from '3-components/Donations/SmallMonthlyNudge/SmallMonthlyNudge';

type FrequencyOption = {
    value: string;
    label: string;
    checked: boolean;
    disabled?: boolean;
}

interface FrequencyOptionsProps {
    fieldLabel: string;
    fieldName: string;
    options: FrequencyOption[];
    onChange: (value: string) => void;
    nudgeLabel?: string;
    enableNudge?: boolean;
    selectedVal?: string;
}

const FrequencyOptions = (props: FrequencyOptionsProps): JSX.Element => {
    const { fieldLabel, fieldName, options, onChange, enableNudge = true, nudgeLabel = '', selectedVal } = props;

    const [selectedValue, setSelectedValue] = useState(() => {
        if (selectedVal) {
            return selectedVal;
        }
        return options.find(option => {
            return option.checked;
        })?.value;
    });

    useEffect(() => {
        if (selectedVal) {
            setSelectedValue(selectedVal);
            onChange(selectedVal);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVal]);

    const icon = {
        once: 'eye-open',
        monthly: 'volunteer',
    };

    return (
        <div className="frequency-field-wrapper">
            <SegmentedButtonField
                label={fieldLabel}
            >
                <SegmentedButtons>
                    {options.map(option => {
                        const { label, value, disabled = false } = option;
                        return (
                            <SegmentedButtonItem
                                label={label}
                                id={`frequency-${value}`}
                                name={fieldName}
                                value={value}
                                checked={value === selectedValue}
                                disabled={disabled}
                                alwaysShowIcon={true}
                                icon={icon[value]}
                                onChange={(): void => {
                                    setSelectedValue(value);
                                    onChange(value);
                                }}
                                key={`frequency-option-${option.value}`}
                            />
                        );
                    })}
                </SegmentedButtons>
            </SegmentedButtonField>

            {enableNudge && (
                <SmallMonthlyNudge
                    label={nudgeLabel}
                />
            )}
        </div>
    );
};

export default FrequencyOptions;
