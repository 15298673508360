// Import the crypto-js library
import CryptoJS from 'crypto-js';

// Define a secret key for encryption
export const SECRET_STORAGE_KEY = 'gyaYVhAyFSZyTbIYAu7ktgJgewawHOd2';

// Function to encrypt data
export function encryptData(data: string): string {
    return CryptoJS.AES.encrypt(data, SECRET_STORAGE_KEY).toString();
}

// Function to decrypt data
export function decryptData(ciphertext: string): unknown {
    const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_STORAGE_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
}
