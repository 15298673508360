import { createContext, type ReactNode, type FC, useContext } from 'react';
import type { DonationWidgetProps } from './libs/types';

interface DonationWidgetProviderProps extends DonationWidgetProps {
    children: ReactNode;
}

export const DonationWidgetContext = createContext<Partial<DonationWidgetProps>>({});

export const useDonationWidgetProps = (): Partial<DonationWidgetProps> => {
    return useContext(DonationWidgetContext);
};

const DonationWidgetProvider: FC<DonationWidgetProviderProps>= ({ children, ...restProps }) => {
    const providerValue = { ...restProps };

    return (
        <DonationWidgetContext.Provider value={providerValue}>
            {children}
        </DonationWidgetContext.Provider>
    );
};

export default DonationWidgetProvider;
