import InlineMessage from '3-components/InlineMessage/InlineMessage';
import { useEffect, useState, type ChangeEvent } from 'react';
import { cn } from 'utils/className';

interface FeeCheckboxProps {
    feeAmount: string;
    label: string;
    message: string;
    className?: string;
    isChecked?: boolean;
    onChange?: (isChecked: boolean) => void;
    onInit?: () => void;
}

const FeeCheckbox = (props: FeeCheckboxProps): JSX.Element => {
    const { feeAmount = '0', label, message, isChecked, onChange, className, onInit } = props;
    const [checked, setChecked] = useState(isChecked);

    const feeLabel = label.replace('[$]', feeAmount);

    const handleCheckboxChange = (el: ChangeEvent<HTMLInputElement>): void => {
        setChecked(el.target.checked);
        if (onChange) {
            onChange(el.target.checked);
        }
    };

    useEffect(() => {
        if (onInit) {
            onInit();
        }
    }, [onInit]);

    return (
        <div className={cn('fee-checkbox-wrapper', className)}>
            <div className="fee-checkbox-field">
                <label className="fee-checkbox-element">
                    <div>
                        <input
                            type="checkbox"
                            onChange={handleCheckboxChange}
                            checked={checked}
                            className="fee-checkbox-input"
                        />
                    </div>
                    {feeLabel && (
                        <div className="fee-checkbox-label">{feeLabel}</div>
                    )}
                </label>
            </div>
            {checked && (
                <div>
                    {message && (
                        <InlineMessage
                            status="success"
                        >
                            {message}
                        </InlineMessage>
                    )}
                </div>
            )}
        </div>
    );
};

export default FeeCheckbox;
