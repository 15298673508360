const Amex = (): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="26" viewBox="0 0 40 26" fill="none">
            <rect x="0.753906" y="0.5" width="38.2537" height="25" rx="4.5" fill="#006FCF" />
            <rect x="0.753906" y="0.5" width="38.2537" height="25" rx="4.5" stroke="#DDDDDD" />
            <path fillRule="evenodd" clipRule="evenodd" d="M29.8799 5.34604H26.7963L25.1846 9.97135L23.5026 5.34604H20.3491V12.548L17.1254 5.34604H14.5326L11.3089 12.7043H13.5514L14.1821 11.2328H17.546L18.1767 12.7043H22.3814V7.5885L24.2735 12.7043H26.0255L27.9176 7.5885V12.7043H29.8799V13.8256H27.2869L25.1846 16.1382L23.0121 13.8256H14.1121V21.1838H22.9421L25.1144 18.8012L27.2869 21.1838H29.8799V22.0947H26.7963L25.1144 20.2728L23.4325 22.0947H12.9908V13.6854H9.62695L13.8317 4.22461H17.8963L19.3679 7.44832V4.22461H24.4137L25.2546 6.67744L26.0957 4.22461H29.8799V5.34604ZM29.8799 15.4373L27.9878 17.4696L29.8799 19.5019V21.1838L26.446 17.4696L29.8799 13.8256V15.4373ZM20.3491 21.1838V19.4319H16.1444V18.3107H20.2789V16.6287H16.1444V15.5075H20.3491V13.8256L23.853 17.4696L20.3491 21.1838ZM14.8128 9.6208L15.794 7.23816L16.7751 9.6208H14.8128Z" fill="white" />
        </svg>
    );
};

export default Amex;
