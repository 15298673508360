import Paypal from '3-components/Donations/Icons/Paypal';
import MasterCard from '3-components/Donations/Icons/MasterCard';
import Visa from '3-components/Donations/Icons/Visa';
import Amex from '3-components/Donations/Icons/Amex';
import UnionPay from '3-components/Donations/Icons/UnionPay';

export type PaymentIconNames = 'paypal' | 'mastercard' | 'visa' | 'amex' | 'unionpay';

const PaymentIconList = {
    paypal: Paypal,
    visa: Visa,
    mastercard: MasterCard,
    amex: Amex,
    unionpay: UnionPay,
};

interface PaymentIconsProps {
    icons: PaymentIconNames[];
}

const PaymentIcons = ({ icons }: PaymentIconsProps): JSX.Element => {
    return (
        <div className="payment-icons-group">
            {icons.map(icon => {
                const DisplayIcon = PaymentIconList[icon];

                if (!DisplayIcon) {
                    return null;
                }

                return (
                    <div className="payment-icon-item" key={`payment-icon-${icon}`}>
                        <DisplayIcon />
                    </div>
                );
            })}
        </div>
    );
};

export default PaymentIcons;
