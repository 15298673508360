export const validateUrl = (url: string): string => {
    const cleanUrl = url.trim();

    // check if starts with http then just return it
    const httpPattern = /^((http|https|ftp):\/\/)/;
    if (httpPattern.test(cleanUrl) || cleanUrl.startsWith('#') || cleanUrl.startsWith('mailto:')) {
        return cleanUrl;
    }

    const linkUrl = cleanUrl.startsWith('/') ? cleanUrl : `/${cleanUrl}`;
    return linkUrl;
};

export default validateUrl;
